/* eslint-disable max-len */
import OgImage from '@propertypal/shared/src/resources/images/og-image-sitewide.jpeg';
import PnOgImage from '@propertypal/shared/src/resources/images/propertynews-opengraph.png';
import { MetaDescriptions } from '@propertypal/shared/src/types';
import platform from '@propertypal/shared/src/utils/platform';
import React, { Fragment } from 'react';

interface LatLng {
  latitude?: number;
  longitude?: number;
}

interface Props {
  title: string;
  url: string;
  description?: string;
  shortLink?: string;
  image?: {
    url: string;
    width: number;
    height: number;
  };
  type?: string;
  updatedTime?: string;
  latLng?: LatLng;
  accountNumber?: string;
  twitterExtraData?: MetaDescriptions['twitterExtraData'];
}

export const BASE_URL = platform.url;
export const DEFAULT_DESCRIPTION = platform.select(
  'Browse Through More Than 60,000 Properties For Sale And Rent In Ireland And Northern Ireland. PropertyPal Is Your Best Friend In Property.',
  'Find properties, houses and homes for sale and to rent on the market in Northern Ireland. New builds for sale, commercial property for sale and to rent and land &amp; sites for sale with Propertynews.com',
);
export const DEFAULT_TYPE = 'website';

const DEFAULT_IMAGE = {
  url: `${BASE_URL}${platform.select(OgImage.src, PnOgImage.src)}`,
  width: '1200',
  height: '630',
};

const generateMetaTags = (props: Props) => {
  const url = `${BASE_URL}${props.url}`;
  const description = props.description || DEFAULT_DESCRIPTION;
  const image = props.image || DEFAULT_IMAGE;
  const type = props.type || DEFAULT_TYPE;

  // @ts-ignore
  return (
    <>
      <title>{props.title}</title>
      {/* eslint-disable-next-line react/no-invalid-html-attribute */}
      {!!props.shortLink && <link rel="shortlink" href={props.shortLink} />}

      <link key="link:canonical" rel="canonical" href={url} />
      <meta name="description" content={description} />

      {props.accountNumber && <meta itemProp="pp-gaListingAccountNumber" content={props.accountNumber} />}

      {props.latLng && (
        <>
          {props.latLng.latitude && (
            <meta property="place:location:latitude" content={props.latLng.latitude.toString()} />
          )}
          {props.latLng.longitude && (
            <meta property="place:location:longitude" content={props.latLng.longitude.toString()} />
          )}
        </>
      )}

      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={description} />
      <meta key="twitter:image" name="twitter:image" content={image.url} />

      {/* Twitter Extra Data */}
      {props.twitterExtraData &&
        Object.keys(props.twitterExtraData).map((d, i) => (
          <Fragment key={d}>
            <meta property={`twitter:label${i + 1}`} content={d} />
            <meta property={`twitter:data${i + 1}`} content={props.twitterExtraData ? props.twitterExtraData[d] : ''} />
          </Fragment>
        ))}

      <meta key="og:title" property="og:title" content={props.title} />
      <meta key="og:url" property="og:url" content={url} />
      <meta key="og:description" property="og:description" content={description} />
      <meta key="og:type" property="og:type" content={type} />
      <meta key="og:image" property="og:image" content={image.url} />
      <meta key="og:image:type" property="og:image:type" content="image/jpeg" />
      <meta key="og:image:width" property="og:image:width" content={image.width.toString()} />
      <meta key="og:image:height" property="og:image:height" content={image.height.toString()} />
      {props.updatedTime && <meta property="og:updated_time" content={props.updatedTime} />}
    </>
  );
};

export default generateMetaTags;
