import { hexToRgb } from '@propertypal/shared/src/utils/colour';
import styled, { keyframes, css } from 'styled-components';

const pulsar = (hexCode: string) => keyframes`
  to {
    box-shadow: 0 0 0 25px ${hexToRgb(hexCode, 0.1)};
  }

  0% {
    -webkit-box-shadow: 0 0 0 0 ${hexToRgb(hexCode, 0.6)};
  }
  100% {
    -webkit-box-shadow: 0 0 0 15px ${hexToRgb(hexCode, 0)};
  }

  0% {
    -moz-box-shadow: 0 0 0 0 ${hexToRgb(hexCode, 0.6)};
    box-shadow:  0 0 0 15px ${hexToRgb(hexCode, 0.6)};
  }
  100% {
    -moz-box-shadow: ${hexToRgb(hexCode, 0)};
    box-shadow: ${hexToRgb(hexCode, 0)};
  }
`;

const pulsingMixIn = css<{ color?: string }>`
  animation: pulse 1.55s infinite;
  animation-name: ${(props) => pulsar(props.color || props.theme.primary)};
`;

export const Container = styled.button<{
  $color?: string;
  $hoverColor?: string;
  $inlineButton?: boolean;
  $maxWidth?: string;
  $iconHoverColour?: string;
  $hoverTextColor?: string;
  $buttonDisabled?: boolean;
  $disabledColor?: string;
  $disabledStyling?: string;
  $pulsing?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 10px;
  background-color: ${(props) => props.color || props.theme.primary};
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.4s ease 0s;

  ${(props) => props.$maxWidth && `max-width: ${props.$maxWidth}`};

  ${(props) => props.$pulsing && pulsingMixIn}
  ${(props) =>
    props.$buttonDisabled
      ? `
      cursor: not-allowed;
      opacity: 0.5;
      
      svg {
        color: ${props.$disabledColor};
      }
      p {
        color: ${props.$disabledColor};
      }`
      : `
          &:hover {
            background-color: ${props.$hoverColor || hexToRgb(props.color || props.theme.primary, 1, -55)};
            svg {
              color: ${props.$iconHoverColour};
            }
            p {
              ${props.$hoverTextColor && `color: ${props.$hoverTextColor}`};
            }
          }
  
  `}

  ${(props) => props.$disabledStyling}
`;

export const Text = styled.p<{ $fontColor?: string; $fontSize?: number }>`
  font-weight: bold;
  font-size: ${({ $fontSize }) => $fontSize || 16}px;
  color: ${(props) => props.$fontColor || props.theme.white};
  text-align: center;
`;
